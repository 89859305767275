@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "MiloLight"; /* Name your font */
  src: url("./fonts/MiloWebProCompiled-Light.otf") format("opentype"); /* Adjust the path if needed */
}

@font-face {
  font-family: "MiloMedium"; /* Name your font */
  src: url("./fonts/MiloOT.otf") format("opentype"); /* Adjust the path if needed */
}

body {
  margin: 0;
  font-family: "MiloLight", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

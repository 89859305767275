/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

select {
  background-color: white;
  border: 1px solid rgb(225, 221, 221);
  border-radius: 3px;
  padding: 10px;
  font-size: 12px;
  color: gray;
}
input {
  background-color: white;
  border: 1px solid rgb(225, 221, 221);
  border-radius: 3px;
  padding: 10px;
  font-size: 12px;
  color: gray;
}

.container {
  width: 100%; /* Can be in percentage also. */
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.center {
  margin-left: auto;
  margin-right: auto;
  width: 800px;
}

/**
edit button hover effect
 */
.edit-buttonEffect:hover img{
  width: 15px;
  height: 15px;
}

.edit-buttonEffect img{
  width: 18px;
  height: 18px;
}


.showMobilePrice{
  display: none;
}

.showDesktopPrice{
  display: block;
}

@media (max-width: 639px) {
  .center {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .topImage{
    width: 120px;
  }

  .showMobilePrice{
    display: block;
  }

  .showDesktopPrice{
    display: none;
  }

  .gridViewUIs{
    width:100%;
    display:flex;
    flex-direction:column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .moveRightDiv{
    margin-left: 25%;
    width: 100px;
  }
}

/* Customize the label (the container) */
.container2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  border-color: #60c454;
  border-width: 1px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container2:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked ~ .checkmark {
  background-color: #60c454;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container2 .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

